
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  will-change: top;
  width: 100%;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  @include breakpoint($xl) {
    width: 1200px;
    height: 500px;
  }

  @include breakpoint($md) {
    height: calc(52vw);
  }

  @include breakpoint($sm) {
    height: calc(53vw);
    min-width: 349px;
  }
}

.title {
  @include h1;

  color: $white;
  text-transform: uppercase;
  margin-bottom: 10px;

  @include breakpoint($sm) {
    @include h1Mobile;
  }
}

.paragraph {
  @include t1;

  color: $white;
  width: 600px;
  height: 130px;
  white-space: pre-line;

  @include breakpoint($md) {
    @include t2;

    height: 100px;
    width: 420px;
  }

  @include breakpoint($sm) {
    @include t7;

    height: 108px;
    width: 262px;
  }
}

.content {
  position: absolute;
  width: 70%;
  top: 100px;
  left: 100px;

  @include breakpoint($md) {
    top: 86px;
    left: 20px;
  }

  @include breakpoint($sm) {
    top: 35px;
    left: 18px;
  }
}

.buttons {
  display: flex;
  position: absolute;
  left: 100px;
  bottom: 103px;

  .buttonWhatToSee {
    @include b3;

    color: $white;
    background-color: $primary;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;

    &:hover {
      background-color: $primary-hover;
    }
  }

  .buttonWhatToVisit {
    @include b3;

    color: $secondary-text;
    background-color: $secondary;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;

    &:hover {
      background-color: $secondary-hover;
    }
  }

  .buttonWhereToEat {
    @include b3;

    color: $tertiary-text;
    background-color: $tertiary;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 200px;

    &:hover {
      background-color: $tertiary-hover;
    }
  }

  @include breakpoint($md) {
    left: 20px;
    bottom: 40px;
  }

  @include breakpoint($sm) {
    display: none;
  }
}
