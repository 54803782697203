
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
@import 'CarouselArrow.bel.module';

.button {
  height: 50px;
  width: 50px;
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $white-hover;
    cursor: pointer;
  }

  @include breakpoint($sm) {
    height: 30px;
    width: 30px;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.left {

  svg {
    transform: rotate(90deg);

    path {
      fill: $black;
    }
  }
}

.right {

  svg {
    transform: rotate(270deg);

    path {
      fill: $black;
    }
  }
}
