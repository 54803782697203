
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.eventElement {
  width: 440px;
  height: 130px;
  padding: 15px;
  background-color: $white-80;
  backdrop-filter: blur(2px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  cursor: pointer;
  transition: $card-bg-transition;
  color: $black;

  @include breakpoint($sm) {
    min-height: 90px;
    height: auto;
    padding: 10px;
    gap: 10px;
    width: 100%;
  }

  &:hover {
    background-color: $white;
  }
}

.link {
  width: 100%;
}

.imageWrapper {
  width: 150px;
  height: 100px;
  flex-shrink: 0;

  @include breakpoint($sm) {
    width: 105px;
    height: 70px;
    align-self: center;
  }

  .image {
    width: 100%;
    height: 100%;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  flex-grow: 1;
}

.date {
  margin-bottom: 5px;

  @include t4;
}

.name {
  @include cardMain;

  overflow: hidden;
}

.priceWrapper {
  @include t4;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  @include breakpoint($sm) {
    margin-top: 5px;
  }

  svg path {
    fill: $black;
  }
}
