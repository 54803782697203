
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  max-width: $xl;
  height: 100%;
  z-index: 1;
  position: relative;
  margin: 0 auto;
  padding-top: 160px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @include breakpoint($md-and-sm) {
    padding-top: 150px;
    padding-left: 20px;
    padding-right: 20px;
  }

  @include breakpoint($sm) {
    padding-top: 110px;
  }
}

.title {
  margin-bottom: 20px;
  color: $white;
  max-width: 805px;

  @if mixin-exists(bannerTitleDesktop) { @include bannerTitleDesktop; }

  @include breakpoint($md) {
    max-width: 570px;

    @if mixin-exists(bannerTitleTablet) { @include bannerTitleTablet; }
  }

  @include breakpoint($sm) {
    margin-bottom: 10px;
    max-width: unset;
    text-align: center;

    @if mixin-exists(bannerTitleMobile) { @include bannerTitleMobile; }
  }
}

.widgetsWrapper {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  gap: 20px;

  @include breakpoint($sm) {
    gap: 0;
    flex-direction: column;
  }
}

.bottomBlock {
  align-self: flex-end;

  @include breakpoint($sm) {
    align-self: stretch;
  }
}
