
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.buttonGroup {
  position: absolute;
  right: 60px;
  bottom: 40px;
  display: flex;
  width: 110px;
  justify-content: space-between;

  @include breakpoint($md) {
    right: 20px;
  }

  @include breakpoint($sm) {
    right: 20px;
    bottom: 20px;
    width: 70px;
  }
}
