:global(body.region__bel) {

  .button {
    @include breakpoint($sm) {

      svg {
        width: 18px;
        height: 18px;
      }
    }
  }

  .right {

    svg {
      transform: rotate(270deg);

      path {
        fill: $black;
      }
    }

    &__recommendation {
      background-color: transparent;
      height: 0;
    }
  }
}
