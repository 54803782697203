
      @import "regions/kursk/styles/themes/sakhalin/vars.scss";
      @import "regions/kursk/styles/common_vars.scss";
      @import "regions/kursk/styles/themes/sakhalin/fonts.scss";
      @import "regions/kursk/styles/themes/sakhalin/typography.scss";
      @import "regions/kursk/styles/text.scss";
    
.wrapper {
  will-change: top;
  width: 100%;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);

  @include breakpoint($xl) {
    width: 1200px;
    height: 500px;
  }

  @include breakpoint($md) {
    height: calc(52vw);
  }

  @include breakpoint($sm) {
    height: calc(53vw);
    min-width: 349px;
  }
}

.title {
  @include h1;

  color: $white;
  text-transform: uppercase;
  margin-bottom: 10px;

  @include breakpoint($sm) {
    @include h1Mobile;
  }
}

.paragraph {
  @include t1;

  color: $white;
  width: 600px;
  height: 130px;
  white-space: pre-line;

  @include breakpoint($md) {
    @include t2;

    height: 100px;
    width: 450px;
  }

  @include breakpoint($sm) {
    @include t6;

    font-size: 11px; // TODO! Удалить после того, как шрифты будут подгружаться корректно
    height: 108px;
    width: 256px;
  }
}

.content {
  position: absolute;
  width: 70%;
  top: 140px;
  left: 100px;

  @include breakpoint($md) {
    top: 86px;
    left: 20px;
  }

  @include breakpoint($sm) {
    top: 30px;
    left: 18px;
  }
}
